body, html {
    overflow-x: hidden;
    font-family: 'Karla', sans-serif;
}
.slick-list {
    height: 100vh;
}
ul {
    list-style: none;
    padding: 0;
}
/* Verify age */
.verify {
    transition: all 700ms ease;
    background-color: #fff;
    z-index: 77777777;
    position: fixed;
    padding: 15px;
    opacity: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.verify.off {
    opacity: 0;
    z-index: -7;
}
.verify img {
    max-width: 300px;
}
.verify p {
    max-width: 500px;
    margin: auto
}
.verify a {
    color: #d60064;
}
.verify label {
    font-weight: bold;
    color: #d60064;
}
.verify h1 {
    color: #d40d19;
    font-weight: normal;
    text-shadow: none;
}
.btn.btn-verify {
    background-color: #d40d19;
    border-color: #d40d19;
    color: #fff !important;
    text-transform: uppercase;
    padding: .3em 2.5em;
    border-radius: 30px;
}
.btn-verify:hover, .btn-verify:active, .btn-verify:focus {
    border-color: #d40d19;
}
/* Fin Verify age */
.overlay_rotate {
    display: none;
    position: fixed;
    top: 0;
    background-color: #dc4c52;
    color: #000;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    z-index: 777777777 !important;
}
.iconContainer {
    width: 300px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -100px;
}
.iconContainer p {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 20px;
    opacity: 0;
}
.phone {
    position: relative;
    left: 50%;
    margin-left: -80px;
    width: 0;
    height: 0;
    border: 0 solid #000;
    background: #a9a9a9;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom:45px;
}
.phone i {
    text-align: center;
    width: 100%;
    line-height: 120px;
    font-size: 50px;
    color: #454545;
    opacity: 0;
}
.mentions a, .mentions a:hover, .mentions a:active {
    color: #dc4c52;
}
.mentions {
    line-height: 1 !important;
}
.mentions ul li {
    margin-left: 2em;
}
.mentions ul li ul li {
    margin-left: 2em;
}
@media only screen and (orientation: landscape) and (max-width: 820px) {
    .overlay_rotate {
        display: block;
        z-index: 777;
    }
    .overlay_rotate .phone {
        -webkit-animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
        animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
    }
    .overlay_rotate .phone i {
        -webkit-animation: fadeIn .5s .8s forwards ease;
        animation: fadeIn .5s .8s forwards ease;
    }
    .overlay_rotate .iconContainer p {
        -webkit-animation: fadeIn .5s 1.3s forwards ease;
        animation: fadeIn .5s 1.3s forwards ease;
    }
}
@-webkit-keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@-webkit-keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@-webkit-keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@-webkit-keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
@keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
@keyframes expand {
    from{
        top: -300px;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}
nav {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 150;
    background-color: white;
    display: none;
    top: 0;
}
nav .logo-menu {
    margin-bottom: 14vh;
    height: 100px;
    text-align: center;
}
nav .logo-menu img {
    width:100px;
}
nav .networks {
    position: absolute;
    bottom: 15vh;
}
nav .networks div {
    margin-left: auto;
    margin-right: auto;
}
nav .networks img:nth-child(1) {
    padding-right: 30px;
}
nav .networks img {
    height: 36px;
    width: auto;
}
nav .close-menu {
    position: absolute;
    text-align: center;
    cursor: pointer;
    height:100px;
}
nav .close-menu img {
    width:30px;
}
nav ul li {
    color: black;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 0.3vw;
    margin-right: -0.3vw;
    text-align: center;
    padding-bottom: 4vh;
}
nav ul li a {
    text-decoration: none;
    color: black;
}
nav ul li a:hover {
    color: #d40d19;
    text-decoration: none;
}
nav ul .after a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #d40d19;
    transition: width .3s;
    margin:auto;
    padding:0;
}
nav ul .after a:hover::after {
    width: 100px;
}
nav ul .menu-padding {
    padding-top:3vw;
}
@media (max-width: 992px) {
    nav ul li {
        font-size: 2em;
        padding-bottom: 1.5em;
    }
    nav .networks img {
        height: 6vw;
    }
    nav .networks img:nth-child(1) {
        padding-right: 6vw;
    }
}
.logo {
    position: absolute;
    height:100px;
    z-index: 120;
    text-align: center;
}
.logo img {
    width: 100px;
}
@media (max-width: 992px) {
    .logo img {
        width: 100%;
    }
}
.logo-spe{
    text-align: center;
}
.logo-spe img{
    width:100px;
}
.burger {
    position: absolute;
 
    z-index: 120;
    text-align: center;
    cursor:pointer;
}
.burger img {
    width:50px;
}
.img-trans {
    position: absolute;
    z-index: 110;
}
.img-trans img {
    border-top: 17vh solid black;
    border-bottom: 100vh solid black;
    width: 100vw;
    transition: 0.5s ease-out;
}
.img-trans .link {
    position: absolute;
    padding-bottom: 30vh;
    width: 100vw;
    text-align: center;
}
.img-trans .link-1, .img-trans .link-2, .img-trans .link-3 {
    display: none;
}
.img-trans .link a {
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2vw;
    font-size: 1.5vw;
    margin-right: -1vw;
}
@media (max-width: 992px) {
    .img-trans .link {
        position: absolute;
        top: 53vh;
        width: 100vw;
        text-align: center;
    }
    .img-trans .link a {
        letter-spacing: 5vw;
        font-size: 4vw;
    }
}
.opacity {
    opacity: 0.75;
}
.single-item section {
    height: 100vh;
}
.slide-1 {
    background: url('../images/home/fond-1.jpg') center center;
    background-size: cover;
}
.slide-2 {
    background: url('../images/home/fond-2.jpg') center center;
    background-size: cover;
}
.slide-3 {
    background: url('../images/home/fond-3.jpg') center center;
    background-size: cover;
}
.slide-1 .slide-1-black {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    font-size: 26vw;
    letter-spacing: -2vw;
    line-height: 65%;
    padding: 0;
}
.slide-1 .slide-1-black span:nth-child(1) {
    line-height: 73%;
}
.slick-dots {
    position: absolute;
    bottom: 10vh;
    color: white;
    z-index: 110;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0;
    width: 100%;
    text-transform: uppercase;
    font-size: 1vw;
}
@media (max-width: 992px) {
    .slick-dots {
        font-size: 4vw;
        bottom: 18vh;
    }
}
.slick-dots li {
    list-style: none;
}
.nav-elem {
    cursor: pointer;
    writing-mode: vertical-rl;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    transition: 0.2s ease-out;
    position: relative;
    width:5vw;
}
#men-2 {
    width:2vw;
}
.nav-elem-active {
    margin-bottom: -3vw;
}
.line {
    height: 10vw;
    background-color: white;
    width: 2px;
    transition: 0.2s ease-out;
    margin-left: 0.5vw;
}
.line-active {
    height: 13vw;
}
@media (max-width: 992px) {
    .nav-elem {
        width: 15vw;
    }
    #men-2 {
        width:8vw;
    }
    .nav-elem-active {
        margin-bottom: -8vw;
    }
    .line {
        height: 35vw;
    }
    .line-active {
        height: 43vw;
    }
}
.nav-elem::before {
    width: 1px;
    height: 10vw;
    background-color: white;
}
.more-white {
    background-color: grey;
}
.float-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
}
.content h1 {
    text-transform: uppercase;
    color: black;
    letter-spacing: 10px;
    text-align: center;
    font-size: 20px;
    margin-top: 3vw;
    margin-bottom: 5vw;
}
.content p {
    font-size: 1rem;
    line-height: 1.5;
}
.mentions ul li {
    list-style-type: disc;
}
@media (max-width: 992px) {
    .content h1 {
        margin-top: 18vw;
        margin-bottom:8vw;
    }
    .content p {
        font-size: 16px;
        line-height: normal;
    }
}
.content h1 span {
    color: #dc4c52;
    font-size: 30px;
}
#slide-1 {
    height: 30vh;
    position: relative;
    background-size:cover !important;
}
#slide-1 img {
    position: absolute;
    bottom: 0;
}
@media (min-width: 993px) {
    #slide-1 {
        height: 60vh;
    }
}
.block-2 {
    background-color: #dc4c52;
    color: white;
    padding: 4vw 0 0 0;
}
@media (min-width: 993px) {
    .block-2 p {
        text-align: right;
    }
    .block-2 img {
        padding-top: 5vw !important;
        z-index: 120;
    }
}
.patrimoine #slide-1 {
    background: url('../images/patrimoine/patrimoine-header.jpg') center bottom no-repeat fixed;
}
.patrimoine .img-1 {
    z-index: 120;
}
.patrimoine .img-1 img {
    width:100%;
}
@media (min-width: 993px) {
    .patrimoine .img-1 img {
        margin: 20vh 0 0 0;
    }
}
@media (max-width: 992px) {
    .patrimoine .block-1 {
        padding-left:30px;
        padding-right:30px;
    }
    .patrimoine .block-2 p{
        padding:15vw 30px 15vw 30px;
    }
    .patrimoine .block-4 {
        margin-top: 5vw;
        padding-left:30px;
        padding-right:30px;
        margin-bottom:0;
    }
    .patrimoine .block-4 p{
        padding:0;
    }
}
@media (min-width: 993px) {
    .row-neg {
        margin-top: -5vw !important;
        z-index: 80;
    }
    .patrimoine .white-style {
        background-color: white;
        height: 70%;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 80;
    }
    .patrimoine .block-4 {
        margin-top: 10vw;
    }
}
.cuvees a {
    color:black;
    text-decoration: none;
}
.cuvees #slide-1 {
    background: url('../images/cuvees/cuvees-header.jpg') center bottom no-repeat fixed;
}
.cuvees .cuvee {
    text-transform:uppercase;
    text-align: center;
    padding-top:10vh;
    padding-bottom:6vh;
}
.cuvees .cuvee .cuvee-stroke{
    width:9vw;
    height:2px;
    background-color:#dc4c52;
    margin:1.5vh auto 1vh auto;
}
.cuvees .cuvee h2 {
    color:#d40e1a;
    margin-top:6vh;
    font-size:1.2vw;
    line-height: 110%;
}
.cuvees .cuvee h2 span{
    color:black;
    letter-spacing: 0.1vw;
}
.cuvees .cuvee-link{
    letter-spacing: 0.3vw;
    font-size:0.8vw;
}
.cuvees .cuvee picture:nth-child(1) {
    z-index:120;
    position:relative;
}
.cuvees .img-float img {
    position:absolute;
    z-index:80;
}
.cuvees .cuvee-1 {
    background-color:#fbfbf7;
}
.cuvees .cuvee-1 .img-float img:nth-child(1) {
    top:20vh;
    left:8vw;
    width:15vw;
    z-index:130;
    transition: all 500ms ease;
}
.cuvees .cuvee-1 .img-float img:nth-child(2) {
    top:24vh;
    left:5vw;
    width:17vw;
    transition: all 500ms ease;
}
.cuvees .cuvee-1 .img-float img:nth-child(3) {
    top:47vh;
    left:7vw;
    width:10vw;
    z-index:130;
    transition: all 500ms ease;
}
.cuvees .cuvee-1:hover .img-float img:nth-child(1) {
    left:8.5vw;
    top:18vh;
}
.cuvees .cuvee-1:hover .img-float img:nth-child(2) {
    left:4vw;
}
.cuvees .cuvee-1:hover .img-float img:nth-child(3) {
    left:4vw;
}
.cuvees .cuvee-2 {
    background-color:#f7f6f6;
}
.cuvees .cuvee-2 .img-float img:nth-child(1) {
    top:16vh;
    left:4vw;
    width:9vw;
    transition: all 500ms ease;
    z-index: 90;
}
.cuvees .cuvee-2 .img-float img:nth-child(2) {
    top:16vh;
    left:7vw;
    width:15vw;
    transition: all 500ms ease;
}
.cuvees .cuvee-2 .img-float img:nth-child(3) {
    top:47vh;
    left:10vw;
    width:10vw;
    z-index:130;
    transition: all 500ms ease;
}
.cuvees .cuvee-2:hover .img-float img:nth-child(1) {
    left:3vw;
    top:15vh;
}
.cuvees .cuvee-2:hover .img-float img:nth-child(2) {
    left:5vw;
}
.cuvees .cuvee-2:hover .img-float img:nth-child(3) {
    left:6vw;
}
.cuvees .cuvee-3 {
    background-color:#ebebeb;
}
.cuvees .cuvee-3 .img-float img:nth-child(1) {
    top:17vh;
    left:10vw;
    width:12vw;
    transition: all 700ms ease;
    z-index: 130;
}
.cuvees .cuvee-3 .img-float img:nth-child(2) {
    top:37vh;
    left:2vw;
    width:18vw;
    transition: all 500ms ease;
}
.cuvees .cuvee-3:hover .img-float img:nth-child(1) {
    left:9vw;
    top:11vh;
}
.cuvees .cuvee-3:hover .img-float img:nth-child(2) {
    transform:scale(1.1);
}
.cuvees .cuvee-4 {
    background-color:#f9f1f1;
}
.cuvees .cuvee-4 .img-float img:nth-child(1) {
    top:32vh;
    left:3vw;
    width:7vw;
    transition: all 700ms ease;
    z-index: 130;
}
.cuvees .cuvee-4 .img-float img:nth-child(2) {
    top:15vh;
    left:5vw;
    width:15vw;
    transition: all 500ms ease;
    z-index:80;
}
.cuvees .cuvee-4:hover .img-float img:nth-child(1) {
    left:2vw;
}
.cuvees .cuvee-4:hover .img-float img:nth-child(2) {
    transform:scale(1.1);
}
.contact #slide-1 {
    background: url('../images/contact/contact-header.jpg') center bottom no-repeat fixed;
}
.contact .block-form input, .contact .block-form select {
    border: 1px solid white;
    border-bottom: 1px solid #dc4c52;
    border-radius:0;
    padding:0;
    color:#847592;
}
.contact .block-form textarea {
    border:0;
    border-bottom: 1px solid #dc4c52;
    border-radius:0;
    padding:0;
    min-height: 200px;
    color:#272525;
}
.g-recaptcha { visibility: hidden; height: 0; }
.contact .block-form .submit {
    border:1px solid #dc4c52;
    border-radius:0;
    width:100%;
    padding-top:10px;
    padding-bottom:10px;
    text-transform: uppercase;
    letter-spacing: 0.2vw;
    color:#272525;
}
@media (min-width: 993px) {
    .contact .block-form .left {
        padding-right:2vw;
    }
    .contact .block-form .right {
        padding-left:2vw;
    }
}
#gmap {
    margin-top:4vw;
    height: 35vw;
}
@media (max-width: 992px) {
    #gmap {
        margin-top:10vw;
        height: 45vw;
    }
}
.mentions #slide-1 {
    background: url('../images/mentions/mentions-header.jpg') center bottom no-repeat fixed;
}
.mentions h2 {
    color: #dc4c52;
    font-size: 1.5em;
}
.mentions p {
    font-size: 1em;
    line-height: 1.2 !important;
    margin-bottom: 2em;
}
@media (min-width: 993px) {
    .mentions .left {
        padding-right:2em;
    }
    .mentions .right {
        padding-left:2em;
    }
}
@media (max-width: 992px) {
    .mentions h2 {
        font-size: 22px;
        line-height: normal;
    }
    .mentions p {
        font-size: 16px;
        line-height: normal;
        margin-bottom:10em;
    }
}
.art #slide-1 {
    background: url('../images/art/art-header.jpg') center bottom no-repeat fixed;
}
.art .img-1 {
    z-index: 120;
}
.art .img-1 img {
    width:100%;
}
.art .img-2 img {
    width:100%;
}
@media (min-width: 993px) {
    .art .row-neg {
        margin-top: -6vw !important;
    }
    .art .img-1 img {
        margin: 10vh 0 0 0;
    }
    .art .block-2 {
        padding:8vw 0 0 0;
        margin-left:4vw;
    }
    .art .block-2 p {
        text-align: left;
    }
    .art .block-4 {
        text-align: right;
        padding-top: 25vh;
    }
    .art .block-5 {
        padding: 10vh 0 0 0;
    }
}
.art .block-4 .red {
    color: #dc4c52;
}
.art .block-6 .under-block {
    position:relative;
}
.art .block-6 .text-block {
    z-index:120;
}
.art .block-6 {
    padding-top:20vh;
}
.art .block-6 h2 {
    color:#909090;
    font-size:1.5vw;
    padding-left:3vw;
    letter-spacing: 0.2vw;
}
.art .block-6 p {
    color:#272525;
    font-size:1vw;
    padding-left:3vw;
}
.art .block-6 .stroke {
    border-top:2px solid #dc4c52;
    width:6vw;
    margin:3vh 0 2vh 0;
}
.art .block-6 .number-back {
    position:absolute;
    font-size:18vw;
    color:#f6f6f6;
    font-weight:bold;
    top:-10vw;
    left:1vw;
    z-index:80;
    padding:0;
}
.art .block-6 .nuage {
    width:40vw;
    position:absolute;
    bottom:-16vh;
    right:-16vw;
}
.art .block-6 .vautour {
    width:40vw;
    position:absolute;
    top:-45vh;
    left:-13vw;
}
@media (max-width: 993px) {
    .art .block-1 {
        padding:0 30px 0 30px;
    }
    .art .block-2 {
        padding:15vw 15px 15vw 15px;
    }
    .art .block-4 {
        padding-top:15vw;
        padding-bottom:10vw;
        text-align:center;
    }
    .art .block-6 {
        margin-top:5vw !important;
        padding-top:0;
    }
    .art .block-6 .text-block{
        margin-top:15vw;
    }
    .art .block-6 h2 {
        font-size:24px;
        text-align:center;
        padding-left:0;
        margin-top:30px;
    }
    .art .block-6 p {
        font-size:18px;
        padding-left:0;
    }
    .art .block-6 .number-back {
        left:0;
        top:-6vw;
        text-align: center;
        padding:0;
        font-size:30vw;
    }
    .art .block-6 .stroke {
        width:10vw;
        margin:13vw auto 2vw auto;
    }
    .art .block-6 .vautour, .art .block-6 .nuage {
        display:none;
    }
}
@media (min-width: 993px) {
    .art .under-block:nth-child(1) {
        margin-top:22vh;
    }
    .art .under-block:nth-child(3) {
        margin-top:40vh;
    }
    .art .under-block:nth-child(4) {
        margin-top:22vh;
    }
    .art .block-6 {
        margin-bottom:10vh !important;
    }
}
@media (max-width: 993px) {
    .cuvees .cuvee h2 {
        font-size:6vw;
    }
    .cuvees .cuvee .cuvee-link {
        font-size:3.5vw;
    }
    .img-float {
        display:none;
    }
}
.color-1 {
    background-color:#fbfbf7;
}
.color-2 {
    background-color:#f7f6f6;
}
.color-3 {
    background-color:#ebebeb;
}
.color-4 {
    background-color:#f9f1f1;
}
.header-cuvee div:nth-child(1) {
    height:100px;
}
.header-cuvee .back {
    text-transform:uppercase;
    letter-spacing: 0.2vw;
    font-size:0.7vw;
}
.header-cuvee .back a {
    color:black;
    text-decoration: none;
}
.header-cuvee .row p:nth-child(1) {
    padding-left:2vw;
}
.wine {
    padding:20vh 0 15vh 0;
}
.cuvee-right h1 {
    color:#d40e1a;
    text-transform: uppercase;
    font-size:30px;
    margin:0;
}
.cuvee-right h2 {
    color:black;
    text-transform: uppercase;
    font-size:22px;
    letter-spacing: 0.1vw;
}
.cuvee-right h3:nth-child(1) {
    margin-top:4vh;
}
.cuvee-right h3 {
    color:#d40e1a;
    text-transform: uppercase;
    font-size:18px;
}
.goutte-block {
    height:50px;
    margin-top:3vw;
}
@media (max-width: 992px) {
    .wine {
        padding:10vh 0 5vh 0;
    }
    .cuvee-right {
        text-align:center;
    }
    .cuvee-right h1{
        text-align:center;
        margin-top:15vw;
    }
    .cuvee-right h2{
        margin-bottom:15vw;
    }
    .header-cuvee .back {
        visibility:hidden;
    }
    .goutte-block {
        margin-top:10vw;
        margin-bottom:10vw;
    }
}
.goutte-line {
    content: "";
    height: 50px;
    background-color:#cdcdcd;
    bottom: 0;
    width: 2px;
    animation: height infinite 1.5s;
}
.goutte {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #d40e1a;
    margin-left:auto;
    margin-right:auto;
    animation: move infinite 1.5s;
    position:absolute;
}
@keyframes move {
    from {
        margin-top:0;
    }
    to {
        margin-top:55px;
        opacity:0;
    }
}
@keyframes height {
    from {
        height:0;
    }
    to {
        height:50px;
    }
}
.abus {
    background-color: #f6f6f6;
    color: #bba384;
    text-align: center;
    padding: 0.5vw 0 0.5vw 0;
}
.abus p {
    font-size:0.8vw;
}
.triman {
    width: 55px;
}
footer {
    background-color: black;
    padding: 1.5vw 0 1.5vw 0;
}
footer ul {
    color: white;
    margin-bottom: 0;
}
footer ul li a {
    color: white;
    text-decoration:none;
}
footer ul li a:hover {
    color: white;
    text-decoration:none;
}
footer .block-footer-logo{
    text-align:center;
}
footer .footer-logo {
    width: 100px;
}
footer .social img {
    height: 50px;
}
footer .social img:nth-child(1) {
    margin-right: 1vw;
}
@media (min-width: 993px) {
    footer .row div:nth-child(1){
        padding-left:2vw;
    }
    footer .row div:nth-child(3){
        padding-right:2vw;
    }
}
@media (max-width: 992px) {
    .abus p {
        font-size:2.6vw;
    }
    footer {
        text-align:center;
        padding-top:5vw;
        padding-bottom:5vw;
    }
    footer .social img:nth-child(1) {
        margin-right: 5vw;
    }
    footer .row div:nth-child(1){
        padding-top:4vw;
        padding-bottom:2vw;
    }
}
#page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
#contenu {
    flex: 1;
}
.g-recaptcha { 
    visibility: hidden; 
    height: 0; 
}